<template>
    <router-view v-slot="{ Component, route }">
        <transition name="fade">
            <div :key="route.path">
                <component :is="Component" />
            </div>
        </transition>
    </router-view>
</template>

<script setup name="appMain">


</script>

<style lang="scss" scoped>
.fade-enter-from,
.fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}

.fade-enter-active {
    transition: all .7s ease;
}

.fade-enter-active {
    transition: all .5s cubic-bezier(1, 0.6, 0.6, 1);
}


</style>