import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/iconfont/iconfont.js'
import './assets/iconfont/iconfont.css'
import './utils/common.js'
import dir from "./directive/index"
import 'animate.css'
import showCallMe from './components/showCallMe.vue'
import VueAnimateOnScroll from 'vue-animate-onscroll'
import router from './router'
import store from "./store"

const app = createApp(App)
app.component('showCallMe', showCallMe)
app.use(router)
app.use(store)
app.use(VueAnimateOnScroll)
app.use(ElementPlus)
app.directive('animation',dir.hasAnimation)
app.directive('animationTwo',dir.hasAnimationTwo)
app.directive('animationThree',dir.hasAnimationThree)
app.mount('#app')
