export default (function(doc,win){
    const docEl = document.documentElement,
    resizeEvt = 'orientationchange' in window? 'orientationchange' :'resize',
    recalc = function(){
        if(docEl.clientWidth < 760){
            const width = docEl.clientWidth*20 /750
            docEl.style.fontSize = width + 'px'
        }
        if(docEl.clientHeight >= 760){
            const width = docEl.clientWidth *20 / 1920
            docEl.style.fontSize = width + 'px'
        }
    }
    if(!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false)
    doc.addEventListener('DOMContentLoaded', recalc,false)
})(document,window)