import { createRouter, createWebHistory } from "vue-router";
import Layout from "@/layout/index";
const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/dashabord",
    children: [
      {
        path: "/dashabord",
        component: () => import("@/view/dashabord"),
        name: "dashabord",
        meta: { title: "首页" },
      },
    ],
  },
  {
    path: "/robotView",
    component: Layout,
    children: [
      {
        path: "/robotView",
        component: () => import("@/view/robotView/index"),
        name: "robotView",
        meta: { title: "智能机器人" },
      },
    ],
  },
  {
    path: "/bigData",
    component: Layout,
    children: [
      {
        path: "/bigData",
        component: () => import("@/view/bigData/index"),
        name: "bigData",
        meta: { title: "大数据拓客" },
      }
    ],
  },
  {
    path: "/crm",
    component: Layout,
    children: [
      {
        path: "/crm",
        component: () => import("@/view/crm/index"),
        name: "crm",
        meta: { title: "crm系统" },
      }
    ],
  },
  {
    path: "/tts",
    component: Layout,
    children: [
      {
        path: "/tts",
        component: () => import("@/view/tts/index"),
        name: "tts",
        meta: { title: "tts语音" },
      }
    ],
  },
  {
    path: "/backCall",
    component: Layout,
    children: [
      {
        path: "/backCall",
        component: () => import("@/view/backCall/index"),
        name: "backCall",
        meta: { title: "回拨系统" },
      }
    ],
  },
  {
    path: "/salesCard",
    component: Layout,
    children: [
      {
        path: "/salesCard",
        component: () => import("@/view/salesCard/index"),
        name: "salesCard",
        meta: { title: "呼大侠电销卡" },
      }
    ],
  },
  {
    path: "/service",
    component: Layout,
    children: [
      {
        path: "/service",
        component: () => import("@/view/service/index"),
        name: "service",
        meta: { title: "服务保障" },
      }
    ],
  },
  {
    path: "/helpCenter",
    component: Layout,
    children: [
      {
        path: "/helpCenter",
        component: () => import("@/view/helpCenter/index"),
        name: "helpCenter",
        meta: { title: "帮助中心" },
      }
    ],
  },
  {
    path: "/helpInfo",
    component: Layout,
    children: [
      {
        path: "/helpInfo",
        component: () => import("@/view/helpCenter/components/helpInfo.vue"),
        name: "helpInfo",
        meta: { title: "帮助中心详情" },
      }
    ],
  },
  {
    path: "/companyProfile",
    component: Layout,
    children: [
      {
        path: "/companyProfile",
        component: () => import("@/view/companyProfile/index"),
        name: "companyProfile",
        meta: { title: "关于呼大侠" },
      }
    ],
  },
  {
    path: "/groupCall",
    component: Layout,
    children: [
      {
        path: "/groupCall",
        component: () => import("@/view/groupCall/index"),
        name: "groupCall",
        meta: { title: "群呼系统" },
      }
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
