import { defineStore } from "pinia";
export const useIndexSore = defineStore("index", {
    state: () => ({
        activeIndex: localStorage.getItem('activeIndex') || "/",
        noticeBar: localStorage.getItem('noticeBar') || true
    }),
    getters: {
      testGetters(state) {
        // 自动补全！ ✨
        return state.testState;
      },
    },
    actions: {},
  });