import { useIntersectionObserver } from '@vueuse/core'
export default {
    hasAnimation:(el,binding)=>{
        useIntersectionObserver(
            el,
            ([{ isIntersecting }], observerElement) => {
                if (isIntersecting) {
                    el.classList.add('animate__zoomInDown')
                }
            },
        )
    },
    hasAnimationTwo:(el,binding)=>{
        useIntersectionObserver(
            el,
            ([{ isIntersecting }], observerElement) => {
                if (isIntersecting) {
                    el.classList.add('animate__pulse')
                }
            },
        )
    },
    hasAnimationThree:(el,binding)=>{
        useIntersectionObserver(
            el,
            ([{ isIntersecting }], observerElement) => {
                if (isIntersecting) {
                    el.classList.add('animate__rollIn')
                }
            },
        )
    }
}