import request from './request'


export const getAddTrail = (data) => {
  return request({
    url: '/v1/home/addTryUser',
    method: 'post',
    data
  })
}
export const getHelpCenterList = () => {
  return request({
    url: '/v1/home/getOperateList',
    method: 'get'
  })
}
export const getHelpCenterInfo = (id) => {
  return request({
    url: '/v1/home/getOperateInfo?id='+ id,
    method: 'get'
  })
}
export const getUpdateLogList = () => {
  return request({
    url: '/v1/home/getLogList',
    method: 'get'
  })
}
export const getUpdateLogInfo = (id) => {
  return request({
    url: '/v1/home/getLogInfo?id='+ id,
    method: 'get'
  })
}

export const getNoticeBar = () => {
  return request({
    url: '/v1/home/getNoticeList',
    method: 'get'
  })
}