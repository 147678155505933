<template>
  <section>
    <router-view></router-view>
  </section>
</template>

<script>
const ignoreErrors = ["ResizeObserver loop completed with undelivered notifications", "ResizeObserver loop limit exceeded"]
window.addEventListener('error', e => {
  let errorMsg = e.message;
  ignoreErrors.forEach(m => {
    if (errorMsg.startsWith(m)) {
      if (e.error) {
      }
      const resizeObserverErrDiv = document.getElementById(
        'webpack-dev-server-client-overlay-div'
      );
      const resizeObserverErr = document.getElementById(
        'webpack-dev-server-client-overlay'
      );
      if (resizeObserverErr) {
        resizeObserverErr.setAttribute('style', 'display: none');
      }
      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute('style', 'display: none');
      }
    }
  })
});

function isMobile() {  
    const userAgentInfo = navigator.userAgent;  
    const mobileAgents = ["Android", "iPhone", "iPad", "iPod", "Windows Phone", "BlackBerry", "Symbian"];  
    return mobileAgents.some(agent => userAgentInfo.includes(agent));  
}  
  
if (isMobile()) {  
    console.log('当前在移动端');  
    // window.location.href = 'http://xiaobaikejiai.com'
    // window.location.href = 'http://hudaxia.net'
} else {  
    console.log('当前在PC端');  

}
</script>

<style scoped lang="scss">
html {
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
</style>
