<template>
    <div class="headerBox">
        <div class="paddBox">
            <div class="headerlogo">
                <img src="../../assets/dashbordLog/headerLog.png" alt="">
            </div>
            <div class="headerMenu">
                <el-menu ellipsis :default-active="activeIndex" @select="handleSelect" class="el-menu-popper-demo"
                    mode="horizontal" :popper-offset="66">
                    <el-menu-item index="/">首页</el-menu-item>
                    <el-sub-menu index="/robotView" :popper-offset="1">
                        <template #title>产品介绍</template>
                        <el-menu-item index="/robotView">智能机器人</el-menu-item>
                        <el-menu-item index="/bigData">大数据拓客</el-menu-item>
                        <el-menu-item index="/crm">crm系统</el-menu-item>
                        <el-menu-item index="/tts">TTS语音</el-menu-item>
                        <el-menu-item index="/backCall">回拨系统</el-menu-item>
                        <!-- <el-menu-item index="/salesCard">呼大侠电销卡</el-menu-item> -->
                        <el-menu-item index="/groupCall">群呼系统</el-menu-item>
                    </el-sub-menu>
                    <el-sub-menu index="/service" :popper-offset="1">
                        <template #title>服务保障</template>
                        <el-menu-item index="/service">服务保障</el-menu-item>
                        <el-menu-item index="/helpCenter">帮助中心</el-menu-item>
                    </el-sub-menu>
                    <el-sub-menu index="/companyProfile" :popper-offset="1">
                        <template #title>关于呼大侠</template>
                        <el-menu-item index="/companyProfile">关于呼大侠</el-menu-item>
                    </el-sub-menu>
                </el-menu>
            </div>
            <div class="headerButton">
                <el-button class="headerButtonTex" @click="handleTrial">申请试用</el-button>
            </div>
        </div>

        <el-dialog v-model="dialogVisible" title="申请试用" width="400" top="30vh" :before-close="handleDialogClose">
            <showCallMe></showCallMe>
        </el-dialog>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useIndexSore } from '@/store/model/app';
import { storeToRefs } from 'pinia';
const store = useIndexSore();
const { activeIndex } = storeToRefs(store);
const router = useRouter();
const dialogVisible = ref(false);


const handleSelect = function (key, path) {
    activeIndex.value = key;
    localStorage.setItem('activeIndex', activeIndex.value)
    //   const route = router.getRoutes().find(item => item.path === key);
    router.push({ path: key });
}
const handleTrial = function () {
    dialogVisible.value = true;
}
const handleDialogClose = function () {
    dialogVisible.value = false;
}
</script>
<style scoped lang="scss">
:deep(.el-menu--horizontal.el-menu) {
    border-bottom: none;
}

/* 大型设备（笔记本电脑/台式机，992 像素及以上） */
@media only screen and (min-width: 1092px) {
    .headerBox {
        width: 1092px;
        height: 100%;
        margin: auto;
    }
}

/* 超大型设备（大型笔记本电脑和台式机，1200 像素及以上） */
@media only screen and (max-width: 1092px) {
    .headerBox {
        width: 100%;
        height: 100%;
    }
}

.headerBox {
    height: 75px;
    background-color: #fff;
    display: flex;
    align-items: center;

    @media screen and (max-width: 875px) {
        .paddBox {
            padding: 0 !important;
        }
    }

    .paddBox {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 55px;

        .headerlogo {
            height: 55px;
            width: 120px;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        @media screen and (max-width: 875px) {
            // .headerMenu {
            //     display: none !important;
            // }
        }

        .headerMenu {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            margin-left: 50px;

            .el-menu-popper-demo {
                width: 100%;
            }
        }

        .headerButton {
            margin-left: auto;
            width: 120px;

            .headerButtonTex {
                background-color: #3f68f5;
                color: #fff;
                border-radius: 15px;
                cursor: pointer;
            }
        }
    }
}
</style>