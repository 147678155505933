<template>
  <div class="hello">
    <div class="iconfont fa-bodadianhua"></div>
    <div>电话联系:</div>
    <div>13261388898</div>
  </div>
</template>

<script setup name="showCallMe">
</script>
<style scoped lang="scss">
.hello {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  .fa-bodadianhua {
    color: #006bec;
  }
}
</style>
