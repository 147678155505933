<template>
    <div class="footerBox">
        <div>
            <div class="bgNight animate__animated" v-animationTwo ref="target6">
                <div class="bgNightBox animate__animated" :class="{ 'animate__bounce': isIntersect6 }">
                    <div class="bgNightContentCenter">
                        <div class="bgNightContentCenterBox">
                            <div class="CenterText">高效服务 专属客服团队在线售后</div>
                            <div class="CenterTextBox">
                                <div>致力于提供一流的通信解决方案和服务</div>
                                <div>推动企业智能化，数字化发展</div>
                            </div>
                            <div class="centerInp">
                                <el-input placeholder="请输入手机号码" v-model="phoneNum">
                                    <template #prepend>+86</template>
                                    <template #append>
                                        <div class="heiper" @click="handleTrial">立即试用</div>
                                    </template>
                                </el-input>
                            </div>
                        </div>
                        <div class="bgNightContentRight">
                            <img src="../../assets/dashbordLog/nightImg1.png" alt="">
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="footBox animate__animated">
            <div class="itemBox animate__animated" v-animationTwo>
                <div class="footItem">
                    <div class="footImg">
                        <img src="../../assets/dashbordLog/footLog1.png" alt="">
                    </div>
                </div>
                <div class="footItem animate__animated">
                    <div class="itemTextTitle">呼大侠产品</div>
                    <div class="itemText" @click="handleToGo('/robotView')">AI电话机器人</div>
                    <div class="itemText" @click="handleToGo('/groupCall')">群呼系统</div>
                    <div class="itemText" @click="handleToGo('/crm')">CRM管理系统</div>
                    <div class="itemText" @click="handleToGo('/bigData')">大数据线索</div>
                    <!-- <div class="itemText" @click="handleToGo('/salesCard')">防封电销卡</div> -->
                    <div class="itemText" @click="handleToGo('/backCall')">回拨系统</div>
                </div>
                <div class="footItem animate__animated">
                    <div class="itemTextTitle">服务保障</div>
                    <div class="itemText" @click="handleToGo('/service')">服务保障</div>
                    <div class="itemText" @click="handleToGo('/helpCenter')">帮助中心</div>
                </div>
                <div class="footItem inter animate__animated">
                    <div class="itemTextTitle">联系我们</div>
                    <div class="itemText" @click="handleToGo('/companyProfile')">关于呼大侠</div>
                    <div class="itemText">加入呼大侠</div>
                    <div class="itemText">联系方式：027-886-00002</div>
                    <div class="itemText">地址：湖北省武汉市洪山区光谷APP广场3号楼605</div>
                </div>
            </div>
            <div class="bottonBox">
                <div class="box animate__animated">
                    <div class="iconBox">
                        <div class="iconBoxItem">
                            <div class="iconfont fa-weixin"></div>
                        </div>
                        <div class="iconBoxItem">
                            <div class="iconfont fa-douyin"></div>
                        </div>
                        <div class="iconBoxItem">
                            <div class="iconfont fa-weibo"></div>
                        </div>
                        <div class="iconBoxItem">
                            <div class="iconfont fa-toutiaoyangshi"></div>
                        </div>
                        <div class="iconBoxItem">
                            <div class="iconfont fa-shejiaotubiao-10"></div>
                        </div>
                    </div>
                    <div class="footerBottom" @click="gotobeian">© {{ icpcode }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { getAddTrail } from '@/api/index'
import { useRouter } from 'vue-router';
import { useIndexSore } from '@/store/model/app';
import { storeToRefs } from 'pinia';
const router = useRouter();
const store = useIndexSore();
const { activeIndex } = storeToRefs(store);
const isIntersect6 = ref(false);
const phoneNum = ref('');
const beian = ref([
    {"host":"www.hudaxia.net", "icp":"鄂ICP备2023021522号-2"},
    {"host":"www.xiaobaikejiai.com", "icp":"鄂ICP备2023021522号-1"}
]);
const icpcode = ref('鄂ICP备2023021522号-2')
const handleTrial = async function () {
    if (!phoneNum.value) {
        ElMessage({
            showClose: true,
            message: '请先输入手机号码',
            type: 'error',
        })
    } else {
        if (!/^1[3456789]\d{9}$/.test(phoneNum.value)) {
            ElMessage({
                showClose: true,
                message: '请输入正确的手机号码',
                type: 'error',
            })
        } else {
            const res = await getAddTrail({ phone: phoneNum.value })
            if (res.code === 0) {
                phoneNum.value = ""
                ElMessage({
                    showClose: true,
                    message: res.msg,
                    type: 'success',
                })
            } else {
                ElMessage({
                    showClose: true,
                    message: res.msg,
                    type: 'error',
                })
            }
        }
    }
};
const handleToGo = function (menu) {
    router.push(menu);
    activeIndex.value = menu;
}
const gotobeian = function() {
    window.open('https://beian.miit.gov.cn/', '_blank')
}
onMounted(() => {
    const host = window.location.host
    beian.value.map((item) => {
        if(host == item.host) {
            icpcode.value = item.icp
        }
    })
})
</script>
<style lang="scss" scoped>
.footerBox {

    .bgNight {
        width: 100%;
        height: 380px;
        display: flex;
        justify-content: center;
        background-color: #4770fb;

        /* 大型设备（笔记本电脑/台式机，992 像素及以上） */
        @media only screen and (min-width: 1092px) {
            .bgNightBox {
                width: 1092px;
                height: 100%;
                margin: auto;
            }
        }

        /* 超大型设备（大型笔记本电脑和台式机，1200 像素及以上） */
        @media only screen and (max-width: 1092px) {
            .bgNightBox {
                width: 100%;
                height: 100%;
            }
        }

        .bgNightBox {
            display: flex;
            align-items: center;

            .bgNightContentCenter {
                width: 100%;
                display: flex;
                align-items: center;

                .bgNightContentCenterBox {
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 32px;

                    .CenterText {
                        color: #fff;
                        font-size: 31px;
                        font-weight: 700;
                    }

                    .CenterTextBox {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        font-size: 24px;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                    }

                    .centerInp {
                        width: 60%;
                        height: 40px;
                        border-radius: 16px;

                        .heiper {
                            cursor: pointer;
                        }
                    }

                    :deep(.el-input-group) {
                        height: 100%;
                    }

                    :deep(.el-input-group__prepend) {
                        border-radius: 16px 0 0 16px;
                    }

                    :deep(.el-input-group__append) {
                        border-radius: 0 16px 16px 0;
                    }

                }

                .bgNightContentRight {
                    width: 165px;
                    height: 145px;
                    margin-left: -45px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .footBox {
        width: 100%;
        height: 100%;
        background-color: #1f1f1f;
        padding-top: 56px;

        .itemBox {
            width: 100%;
            height: 80%;
            display: flex;
            justify-content: center;
            gap: 85px;

            .footItem {
                z-index: 1;
                // width: 25%;
                height: 100%;
                display: flex;
                flex-direction: column;
                text-align: left;
                // align-items: center;
                gap: 12px;

                .footImg {
                    width: 130px;
                    height: 45px;

                    img {
                    object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }

                .itemText {
                    color: #676767;
                    font-size: 14px;
                    cursor: pointer;
                }
                .itemText:hover {
                    color: #409eff;
                }
                .itemTextTitle {
                    font-size: 21px;
                    color: #fff;
                    margin-bottom: 20px;
                    font-weight: 500;
                }
            }
        }

        .bottonBox {
            width: 100%;
            height: 20%;
            padding-top: 16px;

            .box {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .iconBox {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 26px;

                    .iconBoxItem {
                        width: 29px;
                        height: 29px;
                        border: 1px solid #fff;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 29px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        .fa-weixin,
                        .fa-douyin,
                        .fa-shejiaotubiao-10,
                        .fa-toutiaoyangshi,
                        .fa-weibo {
                            color: #fff;
                            font-size: 20px;
                        }
                    }
                }

                .footerBottom {
                    margin-top: 16px;
                    color: #8d8d8d;
                    font-size: 14px;
                }

            }
        }
    }
}
</style>